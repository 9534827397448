angular.module('smart-table').filter('ranges', function () {
  return function (input, min, max) {
    min = parseInt(min);
    max = parseInt(max);
    for (var i = min; i < max; i++) {
      input.push(i);
    }

    return input;
  };
});

angular.module('smart-table').run(['$templateCache', function ($templateCache) {
  $templateCache.put('template/smart-table/pagination.html',
        '<div class="row" >' +
        '<div class="col-12 d-flex justify-content-between" >' +
        '<p ng-if="numPages && pages.length >= 2" class="info"  style="text-align:left">{{"PAGINATION_TEXT" | translate :\'{totalItemCount:\'+(totalItemCount || 0)+\',numPages:\'+(numPages || 0)+\'}\'}}</p> ' +
        '<nav  aria-label="Page navigation"><ul class="pagination" ng-if="numPages && pages.length >= 2">' +
        '<li class="page-item"><a class="btn page-link" href ng-class="{disabled : currentPage === 1}" ng-click="selectPage(1)"><i class="fa fa-fw fa-angle-double-left"></i></a></li>' +
        '<li class="page-item"><a class="btn page-link" href ng-class="{disabled : currentPage === 1}" ng-click="selectPage(currentPage-1)"><i class="fa fa-fw fa-angle-left"></i></a></li>' +
        '<li class="page-item" ng-repeat="page in pages" ng-class="{active: page==currentPage}"><a class="btn page-link" ng-click="selectPage(page)">{{page}}</a></li>' +
        '<li class="page-item"><a class="btn page-link" href  ng-class="{disabled : currentPage === numPages}" ng-click="selectPage(currentPage+1)"><i class="fa fa-fw fa-angle-right"></i></li>' +
        '<li class="page-item"><a class="btn page-link" href ng-class="{disabled : currentPage === numPages}" ng-click="selectPage(numPages)"><i class="fa fa-fw fa-angle-double-right"></i></a></a></li>' +
        '<li class="pagination" ng-if="numPages && pages.length >= 2">' +
        '<select class="custom-select" ng-model="currentPage" ng-change="selectPage(currentPage)"  ng-options="page  for page in   [] | ranges:1:numPages+1"></select>' +
        '</li>' +
        '</i>' +
        '</ul></nav>' +
        '<form class="form-inline table-contentNum" ng-init="limit = \'5\'"><ul class="pagination" >' +
        '<select ng-model="limit" name="limit" id="limit" ng-change="stItemsByPage=limit;selectPage(1)" class="custom-select">' +
        '<option value="5">5</option>' +
        '<option value="10">10</option>' +
        '<option value="50">50</option>' +
        '<option value="100">100</option>' +
        '</select>' +
        '</ul>' +
        '</div>' +
        '</div>' +
        '</form>'
    );
}]);

angular.module('smart-table').directive('searchWatchModel', function () {
  return {
    require: '^stTable',
    scope: {
      searchWatchModel: '='
    },
    link: function (scope, ele, attr, ctrl) {
      var table = ctrl;

      scope.$watch('searchWatchModel', function (val) {
        ctrl.search(val);
      });
    }
  };
});

angular.module('smart-table').directive('stSelectionChange', function ($window) {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      scope.$watch(attrs.ngModel, function (recent, old) {
        if (old === '') {
          old = undefined;
        }
        if (recent === '') {
          recent = undefined;
        }

        if (recent !== old) {
          element.triggerHandler('selected');
        }
      });
    }
  };
});
